/* HaloAICard.module.css */
.root {
    font-family: "Chivo", "Roboto", sans-serif;
  }
  
  .header {
    font-weight: 500;
    margin-bottom: 16px; /* Assuming a base of 8px for material-ui */
    color: #333333 !important;
    font-family: "Chivo", "Roboto", sans-serif;

  }
  
  .body {
    font-weight: 300;
    color: #333333 !important;
    font-family: "Chivo", "Roboto", sans-serif;


  }
  
  .card {
    padding: 24px; 
    margin-bottom: 12px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Sample elevation */
    font-family: "Chivo", "Roboto", sans-serif;

  }
  
  .moreInfoButton {
    margin-top: 32px;
    color: #ffffff;
    background-color: #424242; /* Example of a muted grey color */
    font-family: "Chivo", "Roboto", sans-serif;

  }
  
  .moreInfoButton:hover {
    background-color: #303030;
  }
  