.main-container{
  height: 30vh;
  /* width: 100%; */
  padding-top: 6%;
  margin-left: auto;
  margin-right: auto;

}

.more-info-btn {
  background-color: #f0f0f0; /* Subtle background */
  color: #3B4040 !important;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.25rem; /* Adjusted font size for better readability */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  border-radius: 0.3rem; /* Added border-radius for rounded corners */
}

.more-info-btn:hover {
  color: #3B4040; /* Maintain text color on hover */
  background-color: #e7f3ff; /* Light blue on hover */
}


.content {
  position: relative;
  z-index: 2; /* Ensure content is above the background */
  /* Other styling as needed */
}

.large-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F2F3F5;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: -1; /* Ensure it stays behind other content */
}

.info-card-front, .info-card-back {
  width: 70%;
  margin: auto;
  border-radius: 20px !important;
  /* background: radial-gradient(circle, rgba(249,249,249,1) 9%, rgba(228,231,233,1) 51%, rgba(249,249,249,1) 93%); */
  background-color: #f5f5f5;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Softened shadow */
  font-family: "Chivo", "Roboto", sans-serif;
  margin-bottom: 2%;
}


.info-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* General button styling */
.button {
  border-radius: 8px;
  padding: 0.6rem 1.2rem; /* Adjusted padding */
  margin: 0.3rem; /* Adjusted margin */
  transition: all 0.3s ease;
  border: 2px solid transparent; /* Add border for outlined effect */
}

.button:hover {
  background-color: #f0f0f0;
  transform: translateY(-2px);
}

/* Specific styling for 'More Info' button */
.more-info-btn {
  color: #ffffff !important; /* Green color */
  background-color: #3B4040 !important;
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-transform: none !important;
  font-size: 1.35em !important;
}

.more-info-btn:hover {
  background-color: #e7f3ff; /* Light blue on hover */
}

/* Style for 'Join Waitlist' and 'Go Back' buttons */
.join-waitlist-btn{
  background-color: #3B4040 !important;
  color: #ffffff;
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-transform: none !important;
  padding: 0.75rem 1.5rem; /* Increased padding for better clickability */
  font-size: 1rem; /* Standard font size for buttons */
  border: none; /* Removed border */
}

.MuiButton-label {
  text-transform: none !important;
}

.join-waitlist-btn:hover {
  background-color: #eafaf1; /* Light green on hover */
}

/* Specific styling for 'Go Back' button */
.go-back-btn {
  border-color: #dc3545; /* Red border */
 color: #3B4040 !important;

 width: fit-content;

}

.go-back-btn:hover {
  background-color: #fdecea; /* Light red on hover */
}


.title {
  font-size: 3rem; /* Smaller font size for mobile */
}

.subtitle {
  font-size: 1.5rem; /* Smaller font size for mobile */
}

@media (min-width: 768px) {
  .title {
    font-size: 5rem; /* Larger font size for desktop */
  }

  .subtitle {
    font-size: 2rem; /* Larger font size for desktop */
  }
}

/* Adjust button sizes for mobile */
.join-waitlist-btn, .go-back-btn {
  padding: 0.5rem 1rem; /* Smaller padding for mobile */
}

@media (min-width: 768px) {
  .join-waitlist-btn, .go-back-btn {
    padding: 0.75rem 1.5rem; /* Larger padding for desktop */
  }
}

/* Full width inputs on mobile */
@media (max-width: 767px) {
  .info-card-front, .info-card-back {
    width: 90%; /* Allow more space on the sides */
  }

  .TextField {
    width: 100%; /* Full width for mobile */
  }
}

/* Adjust the layout of form elements */
@media (max-width: 767px) {
  .formControl {
    width: 100%; /* Full width for mobile */
  }
}